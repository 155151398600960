import { useState } from 'react';
import React from 'react';
import { Link } from 'gatsby';
import { useTheme } from 'styled-components';

import { animationContainer, fadeInLeft, fadeInRight } from '../../animations/variants';
import image from '../../assets/images/employer-cycling-hero.png';
import { BookADemo } from '../../components/BookADemo';
import ContactUsForm from '../../components/contact-us-form/ContactUsForm';
import { ScContainer } from '../../components/container/styled';
import { CustomButton } from '../../components/custom-button';
import Modal from '../../components/modal';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTop } from '../../components/Top/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { useMatchMedia } from '../../hooks/use-match-media';
import { ScHeroContainer } from '../EmployeeCycling/styled';

import { ScEmployerCyclingHeroLeft, ScEmployerHeroRight, ScEmployerHeroWrapper } from './styled';

export const EmployerCyclingHero = () => {
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width: 1024px)`);
	const { ref, controls } = useContentAnimation();

	const [isOpenModal, setIsOpenModal] = useState(false);

	return (
		<ScTop disableHeight>
			{isOpenModal &&
				<Modal open={isOpenModal} title={'Contact us'} handleClose={() => setIsOpenModal(false)}>
					<ContactUsForm />
				</Modal>
			}
			<ScHeroContainer
				ref={ref}
				initial="hidden"
				animate={controls}
				variants={animationContainer}
				className={'cycling-page'}
			>
				<ScContainer>
					<ScEmployerHeroWrapper>
						<ScEmployerCyclingHeroLeft variants={fadeInRight}>
							<img src={image} alt="bread" />
						</ScEmployerCyclingHeroLeft>
						<ScEmployerHeroRight variants={fadeInLeft}>
							<h1>Not your average cycle to work scheme</h1>
							<ScParagraph centered={!isDesktop}>
								The biggest savings for your employees and the easiest admin for you
							</ScParagraph>
							<div className="buttons">
								<CustomButton
									color={theme.colors.darkText}
									background={theme.colors.wheat}
									onClick={() => {
										setIsOpenModal(true);
									}}
								>
									Get in touch
								</CustomButton>
								<Link to="/employer-cycling/plans/">
									<CustomButton color={theme.colors.darkText} background={theme.colors.wheat}>
										Sign up
									</CustomButton>
								</Link>
							</div>
						</ScEmployerHeroRight>
					</ScEmployerHeroWrapper>
				</ScContainer>
			</ScHeroContainer>
		</ScTop>
	);
};
